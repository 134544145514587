(function(w,d){

    w.lazyFunctions = {
        // faleconosco: async el => {
        //     const {FaleConosco} = await import('./faleconosco');
        //     return Promise.resolve(FaleConosco(el));
        // },
        // galeria: async el => {
        //     const {Galeria} = await import('./galeria');
        //     return Promise.resolve(Galeria(el));
        // },
        // swaiper_a1: async el => {
        //     const {SwiperA1} = await import('./swiper_a1');
        //     return Promise.resolve(SwiperA1(el));
        // }
        defaultSwiper: async element => {
            const {default: swiper} = await import ('./defaultSwiper.js');
            return Promise.resolve(swiper(element));
        },
        galeria: async element => {
            const {default: fotos} = await import ('./galeria.js');
            return Promise.resolve(fotos(element));
        }
        // ,
        // shareThis: () => {
        //     try {
        //         (function(d, s, id){
        //             var js, fjs = d.getElementsByTagName(s)[0];
        //             if (d.getElementById(id)){ return; }
        //             js = d.createElement(s); js.id = id;
        //             js.onload = function(){
        //                 // remote script has loaded
        //                 console.log('sharethis widget loaded');
        //             };
        //             js.async = true;
        //             js.defer = true;
        //             js.crossorigin = "anonymous"
        //             js.src = "https://platform-api.sharethis.com/js/sharethis.js#property=6626e9d61c60d80019f8b3c4&product=sop";
        //             fjs.parentNode.insertBefore(js, fjs);
        //         }(document, 'script', 'sharethis'));
        //     } catch(err) {
        //         console.error('sharethis load error', err);
        //     }
        // }
    };
    
    w.addEventListener("LazyLoad::Initialized", event => w.lazyLoadInstance = event.detail.instance, false);

    // w.canvasShowElements = () => {
    //     console.log(document.querySelector('body').classList.remove('offc'));
    // };

    // w.canvasHideElements = () => {
    //     console.log(document.querySelector('body').classList.add('offc'));
    // };

    // const offcanvasMenu = document.getElementById('offcanvasMenu');
    // if(offcanvasMenu) {

    //     offcanvasMenu.addEventListener('show.bs.offcanvas', function () {
    //         return w.canvasHideElements();
    //     });

    //     offcanvasMenu.addEventListener('hidden.bs.offcanvas', function () {
    //         // document.querySelector('#q').value = '';
    //         return w.canvasShowElements();
    //     });
    // }

    // const offcanvasSearch = document.getElementById('offcanvasBusca');
    // if(offcanvasSearch) {

    //     offcanvasSearch.addEventListener('show.bs.offcanvas', function () {
    //         return w.canvasHideElements();
    //     });

    //     offcanvasSearch.addEventListener('shown.bs.offcanvas', function () {
    //         document.querySelector('#q').focus();
    //     });

    //     offcanvasSearch.addEventListener('hidden.bs.offcanvas', function () {
    //         document.querySelector('#q').value = '';
    //         return w.canvasShowElements();
    //     });
    // }

    document.addEventListener('minimal-user-interaction', function (event) {
        console.log('minimal-user-interaction');
        const __loader = async () => {
            try {
                const LazyLoad = await import('vanilla-lazyload');
                const lazy = new LazyLoad({
                    unobserve_entered: true,
                    elements_selector: ".lazy, iframe[data-src], [class^='fb-'], .instagram-media, .twitter-tweet, [class^='sharethis-inline-share-buttons']",
                    callback_enter: (element) => {

                        window.dataLayer = window.dataLayer || [];

                        let eventName = null;
                        let removeEntered = false;

                        if(element.hasAttribute('data-lazy-function')) {
                            const lazyFunctionName = element.getAttribute("data-lazy-function");
                            const lazyFunction = w.lazyFunctions[lazyFunctionName];
                            console.log(lazyFunctionName, lazyFunction);
                            if (!lazyFunction) return;
                            return lazyFunction(element);
                        } else if(element.hasAttribute('data-lazy-event')) {
                            eventName = element?.dataset?.lazyEvent || null;
                        } else {
    
                            const classList = String(element.classList);

                            if(classList.match(/fb-*/gi)) {
                                eventName = 'facebook-sdk-event';
                                removeEntered = true;
                            } else if(classList.match(/instagram-media/gi)) {
                                eventName = 'LoaderInstagram';
                            } else if(classList.match(/taboola/gi)) {
                                eventName = 'LoaderTaboolaFeed';
                            } else if(classList.match(/twitter-tweet/gi)) {
                                eventName = 'twitter-event';
                            }
                            // } else if(classList.match(/sharethis-inline-share-buttons/gi)) {
                            //     eventName = 'LoaderShareButtons';
                            // }

                        }
                        
                        if(eventName) {
                            // console.log(eventName);
                            if(removeEntered) element.classList.remove('entered');
                            window.dataLayer.push({'event': eventName});
                        }
                    }
                });
    
                // await import('bootstrap/js/dist/offcanvas');
                // await import('bootstrap/js/dist/collapse');
                // await import('freezeui');
                // await import('fslightbox');
        
                const mobile = window.matchMedia("(max-width: 40em) and (orientation: portrait)");
                if(mobile.matches){
                    // const btn = document.querySelector('#accordionFlushExample .accordion-button');
                    // const item = document.querySelector('#flush-collapseOne');
                    // if(btn && item) {
                    //     // btn.classList.remove('collapsed');
                    //     btn.classList.add('collapsed');
                    //     btn.setAttribute('aria-expanded','false');    
                    //     item.classList.remove('show');
                    // }
                }
    
                document.addEventListener('click', function (event) {
                    let retval = true;
                    if(event?.target.classList.contains('fslightbox')) {
                        event.preventDefault();
                        if( String( event?.target?.dataset?.fslightboxSrc ).length > 0 ) {
                            const lightbox = new FsLightbox();
                            lightbox.props.sources = [ event.target.dataset.fslightboxSrc ];
                            lightbox.open();
                            retval = false;
                        }    
                    }
                    return retval;
                }, false);
    
            } catch(err) {
                console.error('erro ao carregar lazyload', err);
            }
        };
        __loader().then(() => console.log('ok')).catch(() => console.error('failed'));
        return false;
    });

    const gtm_container = document.getElementById('gtm-container');
    console.log(gtm_container);
    if(gtm_container) {
        const gtm_id = gtm_container?.dataset?.id || null;
        if(gtm_id) {
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=false;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer',gtm_id.toString());
            console.log('gtm loaded', gtm_id);
        }
    }

    const toggleButton = document.getElementById('toggleButton');
    if(toggleButton) {
        const cardContainer = document.getElementById('cardContainer');
        const closeButton = document.getElementById('closeButton');
    
        toggleButton.addEventListener('click', () => {
            cardContainer.style.width = cardContainer.style.width === '350px' ? '0' : '350px';
        });
    
        closeButton.addEventListener('click', () => {
            cardContainer.style.width = '0';
        });    
    }

    console.log('main app loaded');

})(window,document);